import React from 'react';
import styled from 'styled-components';
import { ContentWrapper, Section as DefaultSection } from '../common';
import img01Src from '../../images/img-sample-01.png';
import img02Src from '../../images/img-sample-02.png';
import infoSvg from '../../images/svg/icon-info.svg';
import checkSvg from '../../images/svg/icon-check.svg';

const CheckIcon = styled(checkSvg)`
  width: 30px;
  height: auto;
  fill: #f59401;

  @media(min-width: 768px) {
    width: 40px;
  }
`;

const InfoIcon = styled(infoSvg)`
  width: 25px;
  height: auto;
  fill: #f59401;
`;

const Section = styled(DefaultSection)`
  background-color: ${({theme}) => theme.colors.white};
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 1.5rem;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  span {
    font-weight: 600;
    font-size: 1.125rem;

    @media(min-width: 768px) {
      font-size: 1.25rem;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  margin-top: 5rem;
  margin-bottom: 5rem;

  @media(min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media(min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const SectionCaption = styled.p`
  font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #f59401;
  text-align: center;
`;

const Title = styled.p`
  letter-spacing: 0.5px;
  font-size: 24px;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 600;
`;

const Text = styled.p`
  text-align: center;
  /* max-width: 60%; */
  margin-left: auto;
  margin-right: auto;

  strong {
    font-weight: 600;
  }
`;

const Row = styled.p`
  display: grid;
  grid-template-columns: auto 1fr;

  span {
    margin-left: 1rem;
  }
`;

const ImageGrid = styled.div`

  display: grid;
  grid-template-columns: 1fr;
  row-gap: 3rem;
  column-gap: 2rem;

  img {
    width: 100%;
    height: auto;
  }

  ${Title} {
    text-align: left;
    font-size: 1.25rem;
  }

  @media(min-width: 992px) {
    grid-template-columns: 1fr 1fr;
    align-items: center;

    ${Title} {
      font-size: 1.5rem;
    }

    div {
      display: inline-block;
      margin: 0 auto;
    }
  }
`;

const SecondImageGrid = styled(ImageGrid)`
  @media(min-width: 992px) {
    img {
      order: 2;
    }

    div {
      order: 1;
    }
  }
`;

const Divider = styled.div`
  margin-top: 2rem;

  @media(min-width: 992px) {
    margin-top: 5rem;
  }
`;

const NoWrap = styled.span`
  white-space: nowrap;
  font-weight: 400 !important;
`;

function Introduction() {
  return (
    <>
      <Section>
        <ContentWrapper>
          <SectionCaption>Základní informace</SectionCaption>
          <Title as="h2">Co je to <NoWrap>E-TOOLBOX?</NoWrap></Title>
          <Text className="muted"><strong>Jedná se o&nbsp;moderní online software řízení zásob nástrojů</strong>, pomocník vedoucímu výroby, mistrovi, nákupčímu, technologovi, majiteli.</Text>
          <Text>
            Pomáhá s&nbsp;hlídáním a&nbsp;snižováním nákladů spotřeby nástrojů na obrábění. Alternativa výdejnímu automatu.
          </Text>
          <Grid>
            <Item>
              <IconWrapper>
                <CheckIcon />
              </IconWrapper>
              <div>
                <span>Eviduje</span>
                <p>Skladové položky nástrojů pro potřeby výroby.</p>
              </div>
            </Item>

            <Item>
              <IconWrapper>
              <CheckIcon />
              </IconWrapper>
              <div>
                <span>Hlídá</span>
                <p>Stav zásob nástrojů, aby nedošlo k&nbsp;výpadku výroby.</p>
              </div>
            </Item>

            <Item>
              <IconWrapper>
              <CheckIcon />
              </IconWrapper>
              <div>
                <span>Monitoruje</span>
                <p>Spotřebu nástrojů na pracovníka / stroj / zakázku.</p>
              </div>
            </Item>

            <Item>
              <IconWrapper>
              <CheckIcon />
              </IconWrapper>
              <div>
                <span>Komunikuje</span>
                <p>Pravidelné výpisy spotřeby: každý pohyb / denní statistika / týdenní statistika / měsíční statistika.</p>
              </div>
            </Item>

            <Item>
              <IconWrapper>
              <CheckIcon />
              </IconWrapper>
              <div>
                <span>Optimalizuje</span>
                <p>Reporty spotřeby a&nbsp;pohybů pro optimalizaci nákladů obrábění.</p>
              </div>
            </Item>

            <Item>
              <IconWrapper>
              <CheckIcon />
              </IconWrapper>
              <div>
                <span>Rychle</span>
                <p>Zaregistrujte se a&nbsp;účet vám založíme do druhého dne.</p>
              </div>
            </Item>

            <Item>
              <IconWrapper>
              <CheckIcon />
              </IconWrapper>
              <div>
                <span>Bez vstupních nákladů</span>
                <p>Služba <NoWrap>E-TOOLBOX</NoWrap> je zdarma.</p>
              </div>
            </Item>

            <Item>
              <IconWrapper>
              <CheckIcon />
              </IconWrapper>
              <div>
                <span>Bez starostí a&nbsp;s&nbsp;podporou</span>
                <p>Nastavíte hodnoty zásob a&nbsp;pak se věnujete především vaší výrobě. Pomůžeme vám s&nbsp;nastavením systému.</p>
              </div>
            </Item>

            <Item>
              <IconWrapper>
              <CheckIcon />
              </IconWrapper>
              <div>
                <span>Bez omezení</span>
                <p>Počet a&nbsp;druhy položek nejsou omezeny.</p>
              </div>
            </Item>
          </Grid>
          <ImageGrid>
            <img src={img01Src} alt="Foto" />
            <div>
              <Title>Příklady skladových položek:</Title>
              <Row>
                <InfoIcon />
                <span>Řezné nástroje: frézy / vrtáky / závitníky / břitové destičky a&nbsp;pod.</span>
              </Row>
              <Row>
              <InfoIcon />
              <span>Tělesa nástrojů s&nbsp;břitovými destičkami</span>
              </Row>
              <Row>
              <InfoIcon />
              <span>Upínače</span>
              </Row>
              <Row>
                <InfoIcon />
                <span>Ochranné pracovní pomůcky</span>

                </Row>
              <Row>
                <InfoIcon />
                <span>Brusivo</span>
                </Row>
              <Row>
                <InfoIcon />
                <span>Polotovary materiálu</span>

                </Row>
            </div>
          </ImageGrid>
          <Divider />
          <SecondImageGrid>
            <img src={img02Src} alt="Foto" />
            <div>
              <Title>Proč právě <NoWrap>E-TOOLBOX</NoWrap>?</Title>
              <Row>
                <InfoIcon />
                <span>Ušetřete finance za nákup výdejního automatu.</span>
              </Row>
              <Row>
                <InfoIcon />
                <span>Správa konsignačních skladů.</span>
              </Row>
              <Row>
                <InfoIcon />
                  <span>Plaťte jen za spotřebu.</span>
                </Row>
            </div>
          </SecondImageGrid>

        </ContentWrapper>
      </Section>
    </>
  );
}

export default Introduction;