import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { setIn } from 'final-form';
import { Form, Field  } from 'react-final-form';
import * as yup from 'yup';
import { API_URL } from '../../config';
import AresHook from './aresHook';

const validationSchema = yup.object({
  vatId: yup.string().nullable(true).required('Pole je povinné'),
  vatNumber: yup.string().nullable(true).required('Pole je povinné'),
  name: yup.string().nullable(true).required('Pole je povinné'),
  street: yup.string().nullable(true).required('Pole je povinné'),
  city: yup.string().nullable(true).required('Pole je povinné'),
  zip: yup.string().nullable(true).required('Pole je povinné'),
  country: yup.string().nullable(true).required('Pole je povinné'),
  contact: yup.string().nullable(true).required('Pole je povinné'),
  phone: yup.string().nullable(true).required('Pole je povinné'),
	email: yup.string().nullable(true).required('Pole je povinné').email('Email musí být ve správném tvaru'),
});

const validateFormValues = (schema) => async (values) => {
	if (typeof schema === 'function') {
		schema = schema();
	}
	try {
		await schema.validate(values, { abortEarly: false });
	} catch (err) {
		const errors = err.inner.reduce((formError, innerError) => {
			return setIn(formError, innerError.path, innerError.message);
		}, {});

		return errors;
	}
};

const validate = validateFormValues(validationSchema);

const Wrapper = styled.div`
`;

const Input = styled.input`
  height: 40px;
  /* font-size: 0.875rem; */
  padding: 0 0.75rem;
  border: 0;
  width: 100%;
  border-radius: 10px;
  /* font-weight: 500; */
  /* color: #515151; */
  transition: all 0.3s;
  border: 1px solid ${({theme, hasError}) => hasError ? theme.colors.primary : '#8492a6'};

  &:focus {
    outline: 0;
    padding: 0 0.75rem;
    border-color: #6a6b6c;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #8492a6;
    /* font-weight: 300; */
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
  /* font-weight: 300; */
    color: #8492a6;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
  /* font-weight: 300; */
    color: #8492a6;
  }

  @media(min-width: 576px) {
    height: 50px;
  }
`;


const SectionTitle = styled.p`
  font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #f59401;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 3rem;
  row-gap: 2rem;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 3rem;
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 1rem;
`;


const Btn = styled.span`
  display: inline-block;
padding: 11.5px 20px !important;
border-radius: 30px;
cursor: pointer;
outline: none;
font-size: 14px;
line-height: 24px;
  letter-spacing: 0.5px;
  transition: all 0.3s;
  font-weight: 400;
  background-color: #f59401;
  border: 1px solid #f59401;
  color: #fff !important;
  text-align: center;
  box-shadow: 0 3px 7px rgba(98,98,98, 0.5);
  /* margin-top: 2rem; */

  &:hover {
    background-color: #e78f0b;
    border: 1px solid #e78f0b;

  }
`;


const VatWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1rem;
  row-gap: 1rem;

  ${Btn} {
    width: 100%;
  }

  @media(min-width: 576px) {
    grid-template-columns: 1fr auto;
  }

  @media(min-width: 768px) {
    grid-template-columns: 1fr;
  }

  @media(min-width: 992px) {
    grid-template-columns: 1fr auto;
  }
`;


const ErrorMessage = styled.p`
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: ${({theme}) => theme.colors.primary};
  padding-left: 0.75rem;
  margin-bottom: 0;
  margin-bottom: 0;
`;

const BtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    display: inline-block;
  padding: 11.5px 20px !important;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  font-size: 16px;
    letter-spacing: 0.5px;
    transition: all 0.3s;
    font-weight: 600;
    background-color: #f59401;
    border: 1px solid #f59401;
    color: #fff !important;
    text-align: center;
    box-shadow: 0 3px 7px rgba(98,98,98, 0.5);
    /* margin-top: 2rem; */

    &:hover {
      background-color: #e78f0b;
      border: 1px solid #e78f0b;

    }
  }
`;

const SubmitError = styled.p`
  margin-bottom: 2rem;
  font-size: 1.125rem;
  background-color: ${({theme}) => theme.colors.primary};
  color: ${({theme}) => theme.colors.white};
  text-align: center;
  padding: 0.5rem 1rem;
  border-radius: 10px;
`;

const SuccessMessage = styled(SubmitError)`
  background-color:  #18b95c;
  color: ${({theme}) => theme.colors.white};
`;

function ContactForm() {
  const [showError, setShowError] = useState(false);
  const [showAresError, setShowAresError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSent, setSent] = useState(false);
  const [aresData, setAresData] = useState(null);
  const { executeRecaptcha} = useGoogleReCaptcha();

  const loadAresData = async (vatId) => {
    setLoading(true);
    setShowAresError(false);
    const token = await executeRecaptcha("contact_form");
    axios.post(`${API_URL}/ares.php`, {
      vatId,
      token,

    })
    .then(response => {
      setAresData(response.data);
      console.log('witke response', response.data);
    })
    .catch(() => setShowAresError(true))
    .then(() => setLoading(false));
  }

  const onSubmit = async (values, form) => {
    setLoading(false);
    setShowAresError(false);

    const token = await executeRecaptcha("contact_form");

    setShowError(false);
    setSent(false);
    axios.post(`${API_URL}/message.php`, {
      ...values,
      token,

    })
    .then(response => {
      setShowError(false);
      setSent(true);
      form.restart();
      window.dataLayer.push({'event': `formSubmit-contact`});
    })
    .catch(error => {
      setShowError(true);
      setSent(false);
    })
  }

  return (
    <Wrapper>
      <Form
      onSubmit={(values, form) => onSubmit(values, form)}
      initialValues={{
        vatId: '',
        vatNumber: '',
        name: '',
        street: '',
        city: '',
        zip: '',
        country: 'Česká republika',
        contact: '',
        phone: '',
        email: '',
        web: '',
      }}
      validate={validate}
      render={({ handleSubmit, submitting}) => (
        <>
        <AresHook data={aresData} />
        <form onSubmit={handleSubmit}>
          <Grid>
            <div>
            <SectionTitle>Základní informace</SectionTitle>
            <Field
              name="vatId"
              type="text"
            >
              {({ input, meta }) => (
                <InputWrapper>
                  <VatWrapper>
                    <div>
                      <Input {...input} hasError={meta.error && meta.touched} placeholder="IČ" autoComplete="new-password" />
                      {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                      {showAresError && <ErrorMessage>Nastala chyba při získávání dat z IS ARES!</ErrorMessage>}
                    </div>
                    <div>
                      <Btn onClick={() => loadAresData(input.value)}>Předvyplnit data podle IČ</Btn>
                    </div>
                  </VatWrapper>
                </InputWrapper>
              )}
            </Field>

            <Field
              name="vatNumber"
              type="text"
            >
              {({ input, meta }) => (
                <InputWrapper>
                  <Input {...input} hasError={meta.error && meta.touched} placeholder="DIČ" disabled={loading} autoComplete="new-password"/>
                  {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                </InputWrapper>
              )}
            </Field>

            <Field
              name="name"
              type="text"
            >
              {({ input, meta }) => (
                <InputWrapper>
                  <Input {...input} hasError={meta.error && meta.touched} placeholder="Jméno/Název" autoComplete="new-password" />
                  {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                </InputWrapper>
              )}
            </Field>

            <Field
              name="street"
              type="text"
            >
              {({ input, meta }) => (
                <InputWrapper>
                  <Input {...input} hasError={meta.error && meta.touched} placeholder="Ulice" autoComplete="new-password"/>
                  {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                </InputWrapper>
              )}
            </Field>

            <Field
              name="city"
              type="text"
            >
              {({ input, meta }) => (
                <InputWrapper>
                  <Input {...input} hasError={meta.error && meta.touched} placeholder="Město" autoComplete="new-password"/>
                  {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                </InputWrapper>
              )}
            </Field>

            <Field
              name="zip"
              type="text"
            >
              {({ input, meta }) => (
                <InputWrapper>
                  <Input {...input} hasError={meta.error && meta.touched} placeholder="PSČ" autoComplete="new-password"/>
                  {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                </InputWrapper>
              )}
            </Field>

            <Field
              name="country"
              type="text"
            >
              {({ input, meta }) => (
                <InputWrapper>
                  <Input {...input} hasError={meta.error && meta.touched} placeholder="Stát" autoComplete="new-password"/>
                  {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                </InputWrapper>
              )}
            </Field>

            </div>
            <div>
            <SectionTitle>Kontaktní informace</SectionTitle>
            <Field
              name="contact"
              type="text"
            >
              {({ input, meta }) => (
                <InputWrapper>
                  <Input {...input} hasError={meta.error && meta.touched} placeholder="Kontaktní osoba"/>
                  {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                </InputWrapper>
              )}
            </Field>

            <Field
              name="phone"
              type="text"
            >
              {({ input, meta }) => (
                <InputWrapper>
                  <Input {...input} hasError={meta.error && meta.touched} placeholder="Telefon"/>
                  {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                </InputWrapper>
              )}
            </Field>

            <Field
              name="email"
              type="text"
            >
              {({ input, meta }) => (
                <InputWrapper>
                  <Input {...input} hasError={meta.error && meta.touched} placeholder="E-mail"/>
                  {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                </InputWrapper>
              )}
            </Field>

            <Field
              name="web"
              type="text"
            >
              {({ input, meta }) => (
                <InputWrapper>
                  <Input {...input} hasError={meta.error && meta.touched} placeholder="Web"/>
                  {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                </InputWrapper>
              )}
            </Field>

            </div>

          </Grid>



          { showError && <SubmitError>Při odesílání žádosti došlo k&nbsp;chybě!</SubmitError> }
          { isSent && <SuccessMessage id="form-submit-success">Váše žádost o&nbsp;registraci byla úspěšně odeslána. Děkujeme za Váš zájem.</SuccessMessage> }
          <BtnWrapper>
            <button type="submit" disabled={submitting}>Odeslat žádost o&nbsp;registraci</button>
          </BtnWrapper>

         </form>
         </>
       )}
       />
    </Wrapper>
  );
}

export default ContactForm;