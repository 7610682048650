import React from 'react';
import styled from 'styled-components';
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import { ContentWrapper, Section as DefaultSection } from '../common';
import ContactForm from './form';
import { RECAPTCHA_SITE_KEY } from '../../config';

const Section = styled(DefaultSection)`
  background-color: #f1f1f1;
`;

const SectionCaption = styled.p`
font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #f59401;
  text-align: center;
`;

const Title = styled.p`
  letter-spacing: 0.5px;
  font-size: 24px !important;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 600;
`;

const Text = styled.p`
  text-align: center;
  /* max-width: 60%; */
  margin-left: auto;
  margin-right: auto;

  strong {
    font-weight: 600;
    white-space: nowrap;
  }
`;

const FormWrapper = styled.div`
  background-color: #fff;
  margin-top: 3rem;
  padding: 1rem;
  border-radius: 10px ;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px ;
  box-shadow: 0 0 10px #e7e7e7;

  @media(min-width: 992px) {
    padding: 2rem;
  }
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;


function Contact() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY} language="cs">
      <Section id="registrace">
        <ContentWrapper>
          <SectionCaption>Kontakt / Registrace</SectionCaption>
          <Title as="h2">
            Zaujal Vás náš <NoWrap>E-TOOLBOX</NoWrap>?
          </Title>
          <Text>
            Vyplňte <strong>registrační formulář</strong> a&nbsp;my Vás budeme obratem kontaktovat.
          </Text>
          <Text>V&nbsp;případěch další dotazů nás neváhejte kontaktovat na tel. čísle: <strong>+420&nbsp;736&nbsp;489&nbsp;366</strong> nebo emailu: <strong>info@e-toolbox.cz</strong></Text>

          <FormWrapper>
            <ContactForm />
          </FormWrapper>
        </ContentWrapper>
      </Section>
    </GoogleReCaptchaProvider>
  );
}

export default Contact;