import React from 'react';
import styled from 'styled-components';
import { ContentWrapper, Section } from './common';

const FooterWrapper = styled.footer`
  ${Section} {
    padding: 0;
    background-color: #f1f1f1;
    padding-top: 0;
    padding-bottom: 1rem;
  }

  p {
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-bottom: 1.25rem;
    text-align: center;
  }

  @media (min-width: 576px) {
    ${Section} {
      padding-bottom: 2rem;
    }
    p {
      font-size: 1rem;
    }
  }
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;


function Footer() {

  return (
    <FooterWrapper>
      <Section>
        <ContentWrapper>
        <p>
          &copy; <NoWrap>E-TOOLBOX</NoWrap> 2022
        </p>
        </ContentWrapper>
      </Section>
  </FooterWrapper>
  );
}

export default Footer;