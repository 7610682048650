import React from 'react';
import styled, { css } from 'styled-components';
import { ContentWrapper, Section as DefaultSection } from '../common';
import pnlSrc from '../../images/img-sample.png';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import modernSvg from '../../images/svg/icon-idea.svg';
import freeSvg from '../../images/svg/icon-ok.svg';
import noLimitsSvg from '../../images/svg/icon-nolimits.svg';
import communicationSvg from '../../images/svg/icon-support.svg';

const iconStyle = css`
  width: 30px;
  height: auto;
  fill: #fff;

  @media(min-width: 992px) {
    width: 40px;
  }
`;

const ModernIcon = styled(modernSvg)`
  ${iconStyle};
`;

const FreeIcon = styled(freeSvg)`
  ${iconStyle};
`;

const NoLimitsIcon = styled(noLimitsSvg)`
  ${iconStyle};
`;

const SupportIcon = styled(communicationSvg)`
  ${iconStyle};
`;

const Section = styled(DefaultSection)`
  background-color: ${({theme}) => theme.colors.white};
  padding-bottom: 0;
`;

const Btn = styled.span`
  display: inline-block;
  padding: 11.5px 20px !important;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  font-size: 16px;
    letter-spacing: 0.5px;
    transition: all 0.3s;
    font-weight: 600;
    background-color: #f59401;
    border: 1px solid #f59401;
    color: #fff !important;
    text-align: center;
    box-shadow: 0 3px 7px rgba(98,98,98, 0.5);
    margin-top: 2rem;

    &:hover {
      background-color: #e78f0b;
      border: 1px solid #e78f0b;

    }
`;

const Slogan = styled.div`
  text-align: center;

  p {
    font-size: 1.125rem;
    font-weight: 400;

    span {
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  p.muted {
    color: #6a6b6c;
  }

  p.lg {
    font-size: 1.25rem;
    font-weight: 600;

  }

  h1 {
    font-size: 2.5rem;
    font-weight: 600;
    letter-spacing: 3px;
    color: #f59401;
    margin: 1.5rem 0;
    white-space: nowrap;
  }

  @media(min-width: 768px) {
    h1 {
      font-size: 4rem;
    }

    p {
      font-size: 1.375rem;
    }

    p.lg {
      font-size: 1.5rem;
    }
  }
`;

const PanelImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;

  img {
    width: 100%;
    height: auto;
  }
`;

const InfoPanel = styled.div`
  background-color: #f1f1f1 !important;
  padding: 1.5rem 0;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  background-color: #f59401;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;

  img {
    width: 35px;
    height: auto;
  }

  @media(min-width: 992px) {
    width: 60px;
    height: 60px;
    margin-right: 1.5rem;
  }

`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-weight: 600;
    font-size: 1.25rem;
  }

  @media(min-width: 992px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;

  @media(min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

function TopPanel() {
  return (
    <>
      <Section>
        <ContentWrapper>
          <Slogan>
            <p className="muted">Uvažujete o&nbsp;výdejním automatu? <span>Máme pro vás alternativu:</span></p>
            <h1>E-TOOLBOX</h1>
            <p className="lg">Nejoblíbenější řešení správy zásob nástrojů</p>

            <AnchorLink to="/#registrace">
              <Btn>Mám zájem o&nbsp;<NoWrap>E-TOOLBOX</NoWrap></Btn>
            </AnchorLink>
          </Slogan>
          <PanelImgWrapper>
            <img src={pnlSrc} alt="Foto" />
          </PanelImgWrapper>
        </ContentWrapper>
      </Section>
      <InfoPanel>
        <ContentWrapper>
          <Grid>
            <Item>
              <IconWrapper>
                <ModernIcon />
              </IconWrapper>
              <span>Moderní</span>
            </Item>
            <Item>
              <IconWrapper>
                <FreeIcon />
              </IconWrapper>
              <span>Zdarma</span>
            </Item>

            <Item>
              <IconWrapper>
                <NoLimitsIcon />
              </IconWrapper>
              <span>Bez omezení</span>
            </Item>

            <Item>
              <IconWrapper>
                <SupportIcon />
              </IconWrapper>
              <span>Bez starostí</span>
            </Item>
          </Grid>
        </ContentWrapper>
      </InfoPanel>
    </>
  );
}

export default TopPanel;