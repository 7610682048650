import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Contact from '../components/Contact';
import TopPanel from '../components/TopPanel';
import Introduction from '../components/Introduction';

function Index () {

  return (
    <Layout>
      <SEO />
      <TopPanel />
      <Introduction />
      <Contact />
    </Layout>
  )
}

export default Index;
