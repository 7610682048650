import React from 'react';
import styled, {ThemeProvider} from 'styled-components';
import Footer from './footer';
import theme from '../theme';

const PageWrapper = styled.div`
  max-width: 1920px;
  background-color: #ffffff;
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  ${({isFullPage}) => isFullPage && `
    main {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `}
`;

const Content = styled.div`
  flex: 1 0 auto;
`;

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
    <PageWrapper>
      <Content>
          <main>{children}</main>
      </Content>
      <Footer />
    </PageWrapper>
    </ThemeProvider>
  )
}

export default Layout;
