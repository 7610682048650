import { useEffect } from 'react';
import { useForm } from 'react-final-form'

function AresHook({data}) {

  const { change } = useForm();

  useEffect(() => {
    if(data) {
      change('name', data.name);
      change('street', data.street);
      change('city', data.city);
      change('zip', data.zip);
      change('country', data.country);
    }
  }, [data, change]);

  return null;
}

export default AresHook;